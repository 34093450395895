import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Link } from 'gatsby'
// custom
import Layout from 'src/components/view/layout'
import SEO from 'src/components/view/seo'

function NotFoundPage() {
  const [counter, setCounter] = useState(5)

  useEffect(() => {
    setTimeout(() => {
      if (counter) {
        setCounter(counter - 1)
      }
    }, 1000)
    if (counter === 0) {
      navigate('/')
    }
  }, [counter])

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="text-center pt-5 text-white">
        <h1 className="mt-5">NOT FOUND</h1>
        <p className="mt-5">
          <Link to="/">Redirect</Link> to home after {counter} second
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
